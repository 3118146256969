<template>
<div>
    <v-dialog v-model="dialog.turno_lista" persistent max-width="450" :transition="DialogAnimation" :overlay-opacity="TemaDark?'.5':'.25'" overlay-color="black">
        <v-fade-transition>
            <v-card class="rounded-lg" v-show="!DialogTurno">
                <v-card-title class="pa-0 modal-title">
                    <v-toolbar flat class="rounded-t-lg">
                        <v-toolbar-title class="pa-0 text-capitalize" style="line-height: 1.1;width:100%;" >
                            <v-list class="pa-0">
                                <v-list-item class="px-0 rounded-t-lg">
                                    <v-list-item-avatar class="mr-2 rounded-lg" :color="TemaDark?'primary':'secondary'" tile width="8" height="30" min-width="8"/>
                                    <v-list-item-content class="py-0">
                                        <v-list-item-title class="headline pb-0 mb-0">
                                            <v-toolbar-title class="pa-0 trenos-title-toolbar text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">Turnos {{lista.nombre}}</v-toolbar-title>
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="font-weight-medium">
                                            <span class="text-capitalize">Lista</span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-menu v-model="menu_edit" :close-on-content-click="true" :nudge-width="200" origin="right top" rounded="lg"  left transition="scale-transition" max-width="200" v-if="edit && seleccion.lista.length">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on">
                                                    <v-icon color="primary" size="20">mdi-cog-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list dense>
                                                <v-list-item @click="show_nulo_card = true">
                                                    <v-list-item-icon class="mr-3">
                                                        <v-icon size="20">mdi-playlist-remove</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Anular Turnos</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-list-item-icon class="mr-3">
                                                        <v-icon size="20">mdi-trash-can-outline</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Eliminar Turnos</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            
                        </v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-card flat>
                    <v-tabs centered v-model="tab_vista" height="42" >
                        <v-tab class="rounded-lg" v-for="(grupo, index) in lista.datos" :key="index">{{FormatTab(grupo.nombre)}}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab_vista">
                        <v-tab-item eager transition="none" reverse-transition="none" v-for="(tipo, i) in lista.datos" :key="i">
                            <v-card flat max-height="70vh" class="scroll-card">
                                <v-list class="rounded-lg mt-2">
                                    <v-list-item v-if="tipo.nuevo && tipo.nuevo.length" class="px-2">
                                        <v-list-item-content class="py-0">
                                            <v-card outlined class="pb-2 rounded-lg" :color="TemaDark?'grey darken-3':'secondary'">
                                                <p class="text-subtitle-2 text-capitalize ml-4 mt-3" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">
                                                    <v-icon size="15" style="margin-bottom:2px;" :color="TemaDark?'grey lighten-3':'secondary'">
                                                        mdi-information-outline
                                                    </v-icon>
                                                    Nuevos Turnos
                                                </p>
                                                <v-list-item flat v-for="item in tipo.nuevo" :key="item.id" class="rounded-lg" :class="edit?'pl-0 pr-2':'px-2'">
                                                    <v-list-item-avatar class="ma-0" size="40" v-if="edit">
                                                        <v-checkbox v-model="seleccion.lista" :value="item.id" dense class="ml-2 my-0" hide-details></v-checkbox>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content class="py-0 rounded-lg">
                                                        <v-list-item @click="GetTurno(item.id)" :class="edit?'px-2':'px-2'" class="py-1">
                                                            <v-list-item-avatar class="my-1 mr-2" size="30" v-if="!edit">
                                                                <v-avatar color="grey lighten-5" size="30">
                                                                    <v-icon size="15" color="primary">mdi-text-box-search-outline</v-icon>
                                                                </v-avatar>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content class="py-0">
                                                                <v-list-item-title class="text-subtitle-2 mb-0" :class="TemaDark?'grey--text text--lighten-3':'primary--text'">
                                                                    Turno <span class="text-uppercase">{{item.turno}}</span>
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle class="text-capitalize mb-0" :class="TemaDark?null:'primary--text'">
                                                                    <v-icon size="15" :color="TemaDark?'grey lighten-3':'primary'" style="margin-bottom: 2px !important;">mdi-map-marker-outline</v-icon>
                                                                    {{ Lugar(item.entrada, item.salida, item.turno) }}
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle class="mb-0 " :class="TemaDark?null:'primary--text'">
                                                                    <v-icon size="15" :color="TemaDark?'grey lighten-3':'primary'" style="margin-bottom: 2px !important;">mdi-calendar-outline</v-icon>
                                                                    {{ FormatoFecha(item.fecha_inicio, item.fecha_termino) }}
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle class="text-capitalize mb-0 " :class="TemaDark?null:'primary--text'" v-if="item.obs">
                                                                    <v-icon size="15" :color="TemaDark?'grey lighten-3':'primary'" style="margin-bottom: 2px !important;">mdi-information-outline</v-icon>
                                                                    {{ item.obs }}
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                            <v-list-item-action class="my-0 avatar-otros pt-1 ml-1 text-center">
                                                                <span class="text-subtitle-2" :class="TemaDark?'grey--text':'primary--text'" >
                                                                    {{ item.inicio }} - {{ item.termino }} 
                                                                    <p class="mb-0 mt-n1">
                                                                        <v-icon size="12" :color="TemaDark?'grey lighten-3':'primary'" style="margin-bottom: 2px !important;">mdi-clock-outline</v-icon>
                                                                        {{ item.duracion }}   
                                                                    </p>
                                                                </span>
                                                            </v-list-item-action>
                                                        </v-list-item>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-card>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item flat v-for="item in tipo.lista" :key="item.id" class="rounded-lg" :class="edit?'pl-2':'px-2'">
                                        <v-list-item-avatar class="ma-0" size="40" v-if="edit">
                                            <v-checkbox v-model="seleccion.lista" :value="item.id" dense class="ml-2 my-0" hide-details></v-checkbox>
                                        </v-list-item-avatar>
                                        <v-list-item-content class="py-0 rounded-lg">
                                            <v-list-item @click="GetTurno(item.id)" :class="edit?'px-2':'px-2'" class="py-1">
                                                <v-list-item-avatar class="my-1 mr-2" size="30" v-if="!edit">
                                                    <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="30">
                                                        <v-icon size="15" :color="TemaDark?'grey lighten-4 ':'grey'">mdi-text-box-search-outline</v-icon>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="py-0">
                                                <v-list-item-title class="text-subtitle-2" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">
                                                    Turno <span class="text-uppercase">{{item.turno}}</span>
                                                </v-list-item-title>
                                                <v-list-item-subtitle class="text-capitalize ">
                                                    <v-icon size="15" class="mb-1">mdi-map-marker-outline</v-icon>
                                                    {{ Lugar(item.entrada, item.salida, item.turno) }}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" v-if="item.fecha_inicio && item.fecha_termino">
                                                    <v-icon size="15" style="margin-bottom: 2px !important;">mdi-calendar-outline</v-icon>
                                                    {{ FormatoFecha(item.fecha_inicio, item.fecha_termino) }}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle class="text-capitalize " :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" v-if="item.obs">
                                                    <v-icon size="15" style="margin-bottom: 2px !important;">mdi-information-outline</v-icon>
                                                    {{ item.obs }}
                                                </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action class="my-0 avatar-otros pt-1 ml-1 text-center">
                                                    <span class="text-subtitle-2 grey--text">
                                                        {{ item.inicio }} - {{ item.termino }}
                                                        <p class="mb-0 mt-n1">
                                                            <v-icon size="12" style="margin-bottom: 2px !important;">mdi-clock-outline</v-icon>
                                                            {{ item.duracion }}   
                                                        </p>
                                                        </span>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                    <v-card flat v-if="edit && show_nulo_card && seleccion.lista.length" class="nulo_card pa-1">
                        <v-list class="py-0">
                            <v-list-item class="px-1 white">
                                <v-list-item-content class="py-0 ">
                                    <v-card outlined class="text-center pa-1 mt-1 py-4 card-nulo">
                                        <v-row>
                                            <v-col cols="8" class="pr-1 pl-5">
                                                <v-menu ref="nulo_menu" v-model="seleccion.menu" :close-on-content-click="false" :return-value.sync="seleccion.fecha" transition="fade-transition" min-width="auto" top origin="botom leftd">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field outlined dense :value="FormatFecha" label="Fecha nulo" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="" />
                                                    </template>
                                                    <v-date-picker v-model="seleccion.fecha" no-title scrollable first-day-of-week=1 multiple>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text color="primary" @click="seleccion.menu = false; $refs.nulo_menu.save([])">
                                                            Limpiar
                                                        </v-btn>
                                                        <v-btn text color="primary" @click="$refs.nulo_menu.save(seleccion.fecha)">
                                                            OK
                                                        </v-btn>
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="4" class="d-flex align-center justify-center pl-1">
                                                <v-btn depressed color="primary" :disabled="!BtnAddNulo" @click="AddNulo">
                                                    Anular
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-card>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="CerrarLista()">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-fade-transition>
    </v-dialog>
    <v-dialog v-model="dialog.turno" persistent max-width="450" :transition="DialogAnimation" :overlay-opacity="TemaDark?'.5':'.25'" overlay-color="black">
        <v-card flat class="rounded-lg">
            <Turno :id="turno_id" :in_dialog="true" :only_read="true" :dialog_btn="true" :vista="'buscar'" />
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import dayjs from "dayjs"
export default {
    name: 'TurnoLista',
    components: {
        Turno: () => import('@/components/Turno')
    },
    props: ['lista', 'turno_id_dialog', 'edit'],
    data() {
        return {
            turno_id: null,
            seleccion: {
                lista : [],
                fecha: [],
                menu: false
            },
            tab_vista: 0,
            menu_edit : false,
            show_nulo_card: false
        }
    },
    computed: {
        ...mapState(['dialog', 'usuario','sistema']),
        Datos() {
            return this.lista
        },
        BtnAddNulo(){
            let habilitado = false
            if(this.seleccion.fecha && this.seleccion.lista.length) habilitado = true
            return habilitado
        },
        FormatFecha() { //Formatea la fecha de inicio al formato DD-MM-YYYY
            let fecha = null;
            if (this.seleccion.fecha.length == 1) fecha = dayjs(this.seleccion.fecha).format("DD-MM-YYYY")
            if (this.seleccion.fecha.length > 1) fecha = `Multiples fechas....`
                
            return fecha;
        },
        DialogTurno(){
            return this.dialog.turno
        },
        DialogEdit(){
            return this.dialog.edit
        },
        DialogLista(){
            return this.dialog.turno_lista
        },
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        },
        LargoTurnoSelect(){
            return this.seleccion.lista.length
        },
        DialogAnimation(){
            let animation = 'fade-transition'
            if(!this.$vuetify.breakpoint.mdAndUp) animation = 'dialog-bottom-transition'
            return animation
        }
    },
    methods: {
        ...mapMutations(["ShowSnackBar", "ErrorRequest", 'CloseDialog','OpenDialog']),
        //Muestra el detalle del turno con base en su id
        GetTurno(turno_id) {
            this.turno_id = turno_id
            this.OpenDialog('turno')
        },
        //Cierra el dialog turno_lista
        CerrarLista() {
            this.CloseDialog('turno_lista')
        },
        //Entrega el nombre del lugar con base en su sigla
        Lugar(lugar_inicio, lugar_termino, turno_nombre) {
            let nombre_lugar_inicio,
                nombre_lugar_termino
            switch (lugar_inicio) {
                case 'pu':
                    nombre_lugar_inicio = 'Puerto'
                    break;
                case 'li':
                    nombre_lugar_inicio = 'Limache'
                    break;
                case 'sa':
                    nombre_lugar_inicio = 'S.Aldea'
                    break;
                case 'eb':
                    nombre_lugar_inicio = 'El Belloto'
                    break;
                case 'eb/sa':
                    nombre_lugar_inicio = 'El Belloto/S.Aldea'
                    break;
            }
            switch (lugar_termino) {
                case 'pu':
                    nombre_lugar_termino = 'Puerto'
                    break;
                case 'li':
                    nombre_lugar_termino = 'Limache'
                    break;
                case 'sa':
                    nombre_lugar_termino = 'S.Aldea'
                    break;
                case 'eb':
                    nombre_lugar_termino = 'El Belloto'
                    break;
                case 'eb/sa':
                    nombre_lugar_termino = 'El Belloto/S.Aldea'
                    break;
            }
            if(turno_nombre && (turno_nombre.includes('rb') || turno_nombre.includes('rl') || turno_nombre.includes('rs'))) return nombre_lugar_inicio
            if(nombre_lugar_inicio && nombre_lugar_termino) return `${nombre_lugar_inicio} - ${nombre_lugar_termino}`
        },
        //Envia los turnos seleccionados a la api para que se anulen en la fecha seleccionada.
        async AddNulo(){
            let datos = JSON.parse(JSON.stringify(this.seleccion))
            this.$delete(datos, 'menu')
            await this.axios.post(`/buscar/turno/edit/nulo`, datos, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                    this.seleccion = {
                        lista : [],
                        fecha: [],
                        menu: false
                    }
                }
            }).catch(err => {
                this.ErrorRequest(err)
            })
        },
        //Entrega el nombre del titulo del tab
        FormatTab(nombre){
            if(nombre.includes('Mañana')) nombre = 'Turnos Mañana'
            if(nombre.includes('Tarde')) nombre = 'Turnos Tarde'
            return nombre
        },
        FormatoFecha(fecha_inicio, fecha_termino){
            let mensaje,
                fecha_actual = dayjs().format('YYYY-MM-DD')
            if(fecha_inicio && fecha_termino){
                if (fecha_inicio == fecha_termino) {
                    if(fecha_inicio == dayjs().format('YYYY-MM-DD')) mensaje = `Válido solo por hoy`
                    else  mensaje = `Válido solo ${dayjs(fecha_inicio).format('DD')} de ${dayjs(fecha_inicio).format('MMMM')}`
                }
                else{
                    if (fecha_actual == fecha_inicio) mensaje = `Válido desde hoy`
                    if (fecha_actual < fecha_inicio) mensaje = `Válido desde ${dayjs(fecha_inicio).format('DD')} de ${dayjs(fecha_inicio).format('MMMM')}`
                    if (fecha_actual == fecha_termino) mensaje = `Válido hasta hoy`
                    if (fecha_actual < fecha_termino) mensaje = `Válido hasta ${dayjs(fecha_termino).format('DD')} de ${dayjs(fecha_termino).format('MMMM')}`
                    //if (fecha_actual > fecha_termino) mensaje = `Este turno ya no es válido`
                }
            }else{
                if(fecha_inicio){
                    if (fecha_actual < fecha_inicio) mensaje = `Válido desde ${dayjs(fecha_inicio).format('DD')} de ${dayjs(fecha_inicio).format('MMMM')}`
                }
                if(fecha_termino){
                    if (fecha_actual > fecha_termino) mensaje = `Este turno ya no es válido`
                }
            }
            return mensaje
        }
    },
    watch: {
        turno_id_dialog: function (val) {
            if (val) this.GetTurno(val)
        },
        DialogLista(val){
            if(val){
                this.tab_vista = 0
                this.seleccion = {
                    lista : [],
                    fecha: [],
                    menu: false
                }
                this.show_nulo_card = false
            }
        },
        LargoTurnoSelect(val){
            if(!val) this.show_nulo_card = false
        }
    }
}
</script>

<style scoped>
.v-list-item--link:before {
    border-radius: 8px !important;
}

.row-nulo {
    position: sticky;
    top: 0;
}

.card-nulo {
    background-color: white;
    z-index: 10;
}

.scroll-card{
    will-change: transform !important;
    scroll-behavior: smooth;
    overflow: auto
}
.nulo_card{
    position: sticky;
    bottom: 0;
}
</style>
